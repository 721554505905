import React from 'react';
import { Link } from "gatsby"


const Nav = ()=> {
    return (
        <div className={"nav-bar"}>
            <Link to="/">Home</Link>
            <Link to="/bio">Bio</Link>
            <Link to="/contact">Get in touch</Link>
        </div>
    )
}

export default Nav;