import React from 'react'
import {graphql, Link} from 'gatsby'

import Layout from '../components/layout'
import { node } from 'prop-types'
import Nav from '../components/nav'

// @ts-ignore
const CategoryPageTemplate = ({data: {contentfulCategory, allContentfulPost } }) => {
    
    let postCount: number = 0;
    let noPostsList = <>No posts so far</>;
    let postList = 
       <>
            {allContentfulPost && allContentfulPost.edges.map(
                // @ts-ignore
                ({node:post})=> {
                    if(post.isShown) 
                        postCount++;
                    else
                        return <></>
                
                    return (
                    <>
                        <Link to={`/post/${post.slug}`}>
                            <b>
                            {post.title}
                        </b></Link>
                        <div>
                            {post.postDate}
                        </div>
                        <div>
                            {post.excerpt && <div dangerouslySetInnerHTML={{__html: post.excerpt.childMarkdownRemark.html }}/>}
                        </div>
                    </>);
                }
            )}
        </>
    
    return  (
    <Layout>
        <Nav/>
        <h3>
            Category: {contentfulCategory.title}
        </h3>
        {postCount === 0? noPostsList: postList}

    </Layout> )
}

export const query = graphql`
query($slug:String) {
    contentfulCategory(slug: {eq: $slug}) {
        id
        title
        post {
            postDate
            slug
            excerpt {
                childMarkdownRemark {
                    html
                  }
            }
        }
    }
    allContentfulPost (
        filter: {category: {slug: {eq: $slug}}},
        sort: {fields: [postDate], order: DESC}
      
      ) {
        edges {
          node {
            id
            postDate (formatString: "MMMM DD, YYYY")
            title
            slug
            isShown
            excerpt {
                childMarkdownRemark {
                    html
                  }
            }
          }
        }
      }
}
`

export default CategoryPageTemplate;